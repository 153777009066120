.datatable-body-row.ng-star-inserted.active {
  background: #161D31 !important;
}

.bootstrap.core-bootstrap.col-12.table-hover .datatable-body-row:hover {
  background-color: #161D31;
  cursor: pointer;
}

.btn-loader {
  margin: -8px 0;
  transform: scale(.8);
}

.disabled-ng-select {
  pointer-events: none;
  opacity: 0.5;
}

.textarea-noResize {
  resize: none;
}

.card-hover:hover {
  background: #2f374c;
}

.bg-nested-card {
  background: #343D55;
}

.nested-card-hover:hover {
  background: #3B4253;
}

.tooltop-danger {

  .tooltip-inner {
    background: #dc3545;
  }
}

.disabled-btn {
  background: #343D55 !important;
  border-color: transparent !important;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}